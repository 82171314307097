
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import Subdivision from '../../../../store/entities/subdivision'

    @Component
    export default class SubdivisionForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        subdivision: Subdivision = new Subdivision();
        private countryList: any;
        private typeList: any;

        data() {
            return {
                countryList: [],
                updateCount: 0,
                typeList: []
            }
        };

        async mounted() {
            this.countryList = await this.$store.dispatch('country/lookUp');
            this.typeList = await this.$store.dispatch('subdivisiontype/lookUp');
        }

        save() {
            (this.$refs.subdivisionForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: this.toEdit ? 'subdivision/update' : 'subdivision/create',
                        data: this.subdivision
                    });
                    (this.$refs.subdivisionForm as any).resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }

        cancel() {
            (this.$refs.subdivisionForm as any).resetFields();
            this.$emit('input', false);
        }

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                if (this.toEdit) {
                    this.subdivision = Util.extend(true, {}, this.$store.state.subdivision.editSubdivision);
                }
                else {
                    this.subdivision = new Subdivision();
                }
            }
        }

        checkCountryInput(rule, value, callback) {
            if (value == null) {
                return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Country'))));
            }
            return callback();
        }

        checkTypeInput(rule, value, callback) {
            if (value == null) {
                return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Type'))));
            }
            return callback();
        }

        subdivisionRule = {
            name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
            code: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
            countryId: [{
                required: true,
                validator: this.checkCountryInput,
                trigger: 'change'
            }],
            typeId: [{required: true, validator: this.checkTypeInput, trigger: 'change'}]
        }
    }
